const state = {
  login: {
    token: false,
    member_id: null,
    name: 'ゲスト',
    unreads: 0,
    expire: 0,
  },
}

const getters = {
  getLogin: (state) => state.login,
  getLoginToken: (state) => state.login.token,
  getLoginMemberID: (state) => state.login.member_id,
  getUnreads: (state) => state.login.unreads,
  hasUnreads: (state) => state.login.unreads > 0,
}

const mutations = {
  saveLoginName: (state, name) => {
    state.login.name = name
  },
  saveUnreads: (state, unreads) => {
    state.login.unreads = unreads
  },
  saveLoginInfo: (state, login) => {
    state.login.token = login.token
    state.login.member_id = login.member_id
    state.login.name = login.name
    state.login.unreads = login.unreads
    state.login.expire = Math.floor(1000 * login.expire)
  },
}

const actions = {
  setLoginName({ commit }, name) {
    commit('saveLoginName', name)
  },
  setUnreads({ commit }, unreads) {
    commit('saveUnreads', unreads)
  },
  setLoginInfo({ commit }, login) {
    commit('saveLoginInfo', login)
  },
  unsetLoginInfo({ commit }) {
    commit('saveLoginInfo', {
      token: false,
      member_id: null,
      name: 'ゲスト',
      unreads: 0,
      expire: 0,
    })
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
