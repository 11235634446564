<template>
  <v-app dark>
    <router-view @theme-settings-click="handleThemeSettings"></router-view>
    <!-- global snackbar -->
    <v-snackbar v-model="snackbar.show" :timeout="3000" bottom right :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark text icon @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
      <template #action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="$store.commit('HIDE_SNACKBAR')">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import colors from 'vuetify/es5/util/colors'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar
      },
      set(val) {
        this.$store.commit('setSnackbar', val)
      },
    },
  },

  created() {
    this.$vuetify.theme.themes.light.primary = colors['indigo'].base
    this.$vuetify.theme.themes.dark.primary = colors['indigo'].base
    this.$vuetify.theme.themes.light.accent = colors['indigo'].lighten3
    this.$vuetify.theme.themes.dark.accent = colors['indigo'].darken3
    this.$vuetify.theme.dark = false
  },

  methods: {
    handleThemeSettings() {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },
  },
}
</script>
