<template>
  <div>
    <app-toolbar class="admin_toolbar" extended />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <div v-if="$route.path !== '/'" class="text-center my-3">
        <router-link :to="{ name: 'TopIndex' }">{{ $t('back_to_top') }}</router-link>
      </div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer theme-footer">
        <v-row justify="center" no-gutters>
          <v-col class="text-center" cols="12"> 北海道知事登録第2種869号 </v-col>
          <v-col class="text-center" cols="12">
            <a href="/static/pdf/agreement.pdf">{{ $t('travel_conditions') }}</a><v-icon right color="white">picture_as_pdf</v-icon>
            <span class="px-2">|</span>
            <a href="/static/pdf/pricetable.pdf">{{ $t('travel_service_fees') }}</a><v-icon right color="white">picture_as_pdf</v-icon>
          </v-col>
          <v-col class="text-center" cols="12">
            <span v-if="$route.path === '/company/'">{{ $t('menu.company_index') }}</span>
            <router-link v-else :to="{ name: 'CompanyIndex' }">{{ $t('menu.company_index') }}</router-link>
            <span class="px-2">|</span>
            <span v-if="$route.path === '/policy/'">{{ $t('menu.policy_index_short') }}</span>
            <router-link v-else :to="{ name: 'PolicyIndex' }">{{ $t('menu.policy_index_short') }}</router-link>
            <span class="px-2">|</span>
            <span v-if="$route.path === '/inquiry/'">{{ $t('menu.inquiry') }}</span>
            <router-link v-else :to="{ name: 'InquiryIndex' }">{{ $t('menu.inquiry') }}</router-link>
          </v-col>
          <v-col class="text-center" cols="12">
            &copy; 株式会社十勝トラベル / 株式會社十勝旅遊 / Tokachi Travel, Inc. {{ new Date().getFullYear() }}
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppToolbar,
    AppFab,
  },
}
</script>
