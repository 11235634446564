<template>
  <div>
    <v-app-bar class="hidden-sm-and-down">
      <v-toolbar-title>
        <router-link :to="{ name: 'TopIndex' }">
          <img src="/static/icon/logo.png" :alt="$t('site_name')" height="50" />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-icon left> person </v-icon>
      <router-link
        :to="{ name: 'AccountIndex' }"
        class="mr-3"
        :class="{ 'active-menu': $route.path.match(/^\/account(\/|\?|$)/) }"
      >
        {{ $t('menu.account_index') }}
      </router-link>
      <v-icon left> receipt_long </v-icon>
      <router-link
        :to="{ name: 'OrderIndex' }"
        class="mr-3"
        :class="{ 'active-menu': $route.path.match(/^\/order(\/|\?|$)/) && !$route.path.match(/^\/order\/form/) }"
      >
        {{ $t('menu.order_index') }}
      </router-link>
      <v-icon left> campaign </v-icon>
      <router-link
        :to="{ name: 'NoticeIndex' }"
        class="mr-3"
        :class="{ 'active-menu': $route.path.match(/^\/notice(\/|\?|$)/) }"
      >
        {{ $t('menu.notice') }}
      </router-link>
      <v-icon left> help </v-icon>
      <router-link
        :to="{ name: 'FaqIndex' }"
        class="mr-3"
        :class="{ 'active-menu': $route.path.match(/^\/faq(\/|\?|$)/) }"
      >
        {{ $t('menu.faq') }}
      </router-link>
      <template v-if="$store.getters.getLoginMemberID === null">
        <v-icon left> login </v-icon>
        <router-link :to="{ name: 'LoginIndex' }">
          {{ $t('menu.login_index') }}
        </router-link>
      </template>
      <template v-else>
        <v-icon left> logout </v-icon>
        <router-link :to="{ name: 'LogoutIndex' }">
          {{ $t('menu.logout_index') }}
        </router-link>
      </template>
    </v-app-bar>
    <v-app-bar class="hidden-md-and-up">
      <v-toolbar-title>
        <router-link :to="{ name: 'TopIndex' }">
          <img src="/static/icon/logo.png" :alt="$t('site_name')" height="50" />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-menu>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list style="min-width: 7em">
          <v-list-item :to="{ name: 'AccountIndex' }">
            <v-list-item-title>{{ $t('menu.account_index') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'OrderIndex' }">
            <v-list-item-title>{{ $t('menu.order_index') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'NoticeIndex' }">
            <v-list-item-title>{{ $t('menu.notice') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'FaqIndex' }">
            <v-list-item-title>{{ $t('menu.faq') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.getLoginMemberID === null" :to="{ name: 'LoginIndex' }">
            <v-list-item-title>{{ $t('menu.login_index') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-else :to="{ name: 'LogoutIndex' }">
            <v-list-item-title>{{ $t('menu.logout_index') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-layout v-if="$route.path !== '/'" row wrap class="pa-3 mt-1">
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer />
      <LocaleSwitch />
    </v-layout>
  </div>
</template>
<script>
import LocaleSwitch from '@/components/locale/LocaleSwitch'

export default {
  name: 'AppToolbar',
  components: {
    LocaleSwitch,
  },

  computed: {
    breadcrumbs() {
      const { matched } = this.$route
      let prev = null
      return matched
        .map((route, index) => {
          const to =
            index === matched.length - 1 ? this.$route.path : 'path' in route ? route.path || '/' : route.redirect
          const text = this.$t('menu.' + route.meta.title)
          const ignored = to === prev || to === prev + '/'
          prev = to
          if (ignored) {
            return null
          }
          return {
            text: text,
            to: to,
            exact: true,
            disabled: false,
          }
        })
        .filter((route) => route !== null)
    },
  },
}
</script>
