import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'top_index',
      metaTags: {
        name: 'robots',
        content: 'noindex',
      },
    },
    children: [
      {
        path: '',
        name: 'TopIndex',
        meta: {
          title: 'top_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/top/Index.vue'),
      },
      {
        path: '/tour',
        component: RouteWrapper,
        meta: {
          title: 'tour_index',
          icon: 'tour',
        },
        children: [
          {
            path: '',
            name: 'TourIndex',
            meta: {
              title: 'tour_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/tour/Index.vue'),
          },
          {
            path: '/tour/:tour_id',
            name: 'TourDetail',
            meta: {
              title: 'tour_detail',
              icon: 'list_alt',
            },
            component: () => import('@/views/tour/Detail.vue'),
          },
        ],
      },
      {
        path: '/notice',
        component: RouteWrapper,
        meta: {
          title: 'notice_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'NoticeIndex',
            meta: {
              title: 'notice_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/notice/Index.vue'),
          },
          {
            path: '/notice/:notice_id',
            name: 'NoticeDetail',
            meta: {
              title: 'notice_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/notice/Detail.vue'),
          },
        ],
      },
      {
        path: '/faq',
        component: RouteWrapper,
        meta: {
          title: 'faq_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'FaqIndex',
            meta: {
              title: 'faq_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/faq/Index.vue'),
          },
        ],
      },
      {
        path: '/inquiry',
        component: RouteWrapper,
        meta: {
          title: 'inquiry_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'InquiryIndex',
            meta: {
              title: 'inquiry_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/inquiry/Index.vue'),
          },
          {
            path: '/inquiry/custommade',
            name: 'InquiryCustommade',
            meta: {
              title: 'inquiry_custommade',
              icon: 'list_alt',
            },
            component: () => import('@/views/inquiry/Custommade.vue'),
          },
        ],
      },
      {
        path: '/company',
        component: RouteWrapper,
        meta: {
          title: 'company_index',
          icon: 'company',
        },
        children: [
          {
            path: '',
            name: 'CompanyIndex',
            meta: {
              title: 'company_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/company/Index.vue'),
          },
        ],
      },
      {
        path: '/policy',
        component: RouteWrapper,
        meta: {
          title: 'policy_index',
          icon: 'policy',
        },
        children: [
          {
            path: '',
            name: 'PolicyIndex',
            meta: {
              title: 'policy_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/policy/Index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login/index',
    component: LayoutAuth,
    meta: { title: 'login_index' },
    redirect: '/login',
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'LoginIndex',
        meta: { title: 'login_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Index.vue'),
      },
    ],
  },
  {
    path: '/logout/index',
    component: LayoutAuth,
    meta: { title: 'logout_index' },
    redirect: '/logout',
    hidden: true,
    children: [
      {
        path: '/logout',
        name: 'LogoutIndex',
        meta: { title: 'logout_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/logout/Index.vue'),
      },
    ],
  },

  {
    path: '/signup/index',
    component: LayoutAuth,
    meta: { title: 'signup_index' },
    redirect: '/signup',
    hidden: true,
    children: [
      {
        path: '/signup',
        name: 'SignupIndex',
        meta: { title: 'signup_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/signup/Index.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: '/password/reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/top',
    component: LayoutDefault,
    redirect: '/',
    meta: {
      title: 'top_index',
    },
    children: [
      {
        path: '/account',
        name: 'AccountIndex',
        meta: {
          title: 'account_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/account/Index.vue'),
      },
      {
        path: '/order/form/:tour_id',
        name: 'OrderForm',
        meta: {
          title: 'order_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/order/Form.vue'),
      },
      {
        path: '/order',
        component: RouteWrapper,
        meta: {
          title: 'order_index',
          icon: 'receipt_long',
        },
        children: [
          {
            path: '',
            name: 'OrderIndex',
            meta: {
              title: 'order_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/order/Index.vue'),
          },
          {
            path: '/order/:order_id',
            name: 'OrderDetail',
            meta: {
              title: 'order_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/order/Detail.vue'),
          },
        ],
      },
      {
        path: '/withdrawal',
        name: 'WithdrawalIndex',
        meta: {
          title: 'withdrawal_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/withdrawal/Index.vue'),
      },
    ],
  },
]
